import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45check_45auth_45global from "/app/middleware/01.check-auth.global.js";
import api_45redirect_45global from "/app/middleware/api-redirect.global.js";
import custom_45domain_45global from "/app/middleware/custom-domain.global.js";
import feature_45flags_45global from "/app/middleware/feature-flags.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45check_45auth_45global,
  api_45redirect_45global,
  custom_45domain_45global,
  feature_45flags_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/app/middleware/admin.js"),
  auth: () => import("/app/middleware/auth.js"),
  guest: () => import("/app/middleware/guest.js"),
  moderator: () => import("/app/middleware/moderator.js"),
  "self-hosted-credentials": () => import("/app/middleware/self-hosted-credentials.js"),
  "self-hosted": () => import("/app/middleware/self-hosted.js"),
  subscribed: () => import("/app/middleware/subscribed.js")
}